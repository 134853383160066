import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from './store';

Vue.use(VueRouter)

/* pages */
const Locales = () => import('./pages/aplicacion/locales/Index' /* webpackChunkName: 'locales' */);
const SeleccionSecretaria = () => import('./pages/aplicacion/SeleccionSecretaria' /* webpackChunkName: 'seleccionSecretaria' */);
const Colegios = () => import('./pages/colegios/Index' /* webpackChunkName: 'colegios' */);
const Registro = () => import('./pages/registro/Index' /* webpackChunkName: 'registro' */);
const Inscritos = () => import('./pages/inscritos/Index' /* webpackChunkName: 'inscritos' */);
const AvanceInscripcion = () => import('./pages/estadisticas/AvanceInscripcion' /* webpackChunkName: 'avanceInscripcion' */);
const InscritosSede = () => import('./pages/estadisticas/sede/Index' /* webpackChunkName: 'inscritosSede' */);
const InscritosPesd = () => import('./pages/estadisticas/pesd/Index' /* webpackChunkName: 'inscritosPesd' */);
const InscritosOficial = () => import('./pages/estadisticas/pesd/InscritosOficial' /* webpackChunkName: 'inscritosOficial' */);

const routes = [
  {
    path: '/',
    name: 'seleccionSecretaria',
    component: SeleccionSecretaria,
    props: {value: true},
    beforeEnter: (to, from, next) => {
      if (Store.state.usuario.rol[0].nombre == 'jefe-admision') {
        next(SeleccionSecretaria);
      } else {
        next({name: 'locales'});
      }
    },
  },
  {
    path: '/locales',
    name: 'locales',
    component: Locales
  },
  {
    path: '/colegios',
    name: 'colegios',
    component: Colegios
  },
  {
    path: '/registro',
    name: 'registro',
    component: Registro
  },
  {
    path: '/inscritos',
    name: 'inscritos',
    component: Inscritos
  },
  {
    path: '/avanceInscripcion',
    name: 'avanceInscripcion',
    component: AvanceInscripcion
  },
  {
    path: '/inscritosSede',
    name: 'inscritosSede',
    component: InscritosSede
  },
  {
    path: '/inscritosPesd',
    name: 'inscritosPesd',
    component: InscritosPesd
  },
  {
    path: '/inscritosOficial',
    name: 'inscritosOficial',
    component: InscritosOficial
  },
  {
    path: '/logout',
    beforeEnter() {
      Store.dispatch('session/logout');
    }
  },
]


const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
