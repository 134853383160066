<template>
  <v-dialog :value="value" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click.native="cerrar">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Selección de Secretaría de Admisión</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row class="mt-2">
          <v-col
            v-for="(nombreZona, j) in zonas"
            :key="j"
            cols="12"
            md="4"
            xl="4"
            sm="6"
          >
            <v-card elevation="8">
              <v-card-title>Zona {{ nombreZona }}</v-card-title>
              <v-divider />
              <v-card-text>
                <v-list
                  flat
                >
                  <v-list-item-group
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in secretarias[`${nombreZona}`]"
                      :key="i"
                      dense
                    >
                      <v-list-item-content
                        @click="cambiarSecretaria(item)"
                      >
                        <a class="underline">{{ item.sadNombre }}</a>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import graphql from '../../utils/graphql';
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data:() => ({
    secretarias: {
      norte: [],
      centro: [],
      sur: []
    }
  }),
  computed: {
    zonas: () => ['norte', 'centro', 'sur'],
    usuario() {
      return this.$store.state.usuario;
    },
    zonasGeograficas() {
      return {
        norte: [1, 2, 3, 4, 5, 15],
        centro: [6, 7, 8, 13, 16],//o'higgins, maule, biobio, metropolitana, ñuble
        sur:[9, 10, 11, 12, 14]//araucania,los lagos, Aysén, magallanes,los rios
      }
    }
  },
  async mounted() {
    try {
      const querySadm = {
        query: `
          query {
            secretariasAdmision {
              sadCodigo
              sadNombre
              regCodigo
              prvCodigo
            }
         }`
       };
      const { secretariasAdmision } = await graphql.request(querySadm);
      if (!secretariasAdmision.length) {
        throw new Error('No se pudo obtener las secretarías de admisión');
      }
      secretariasAdmision.forEach(secretaria => {
        this.asignarSecretaria(secretaria);
      });
    } catch (err) {
      this.$store.commit('error-notification', err.message);
    }
  },
  methods: {
    asignarSecretaria(secretaria) {
      if (this.zonasGeograficas['norte'].includes(secretaria.regCodigo)) {
        this.secretarias['norte'].push(secretaria);
      }
      else if (this.zonasGeograficas['centro'].includes(secretaria.regCodigo)) {
        this.secretarias['centro'].push(secretaria);
      }
      else if (this.zonasGeograficas['sur'].includes(secretaria.regCodigo)) {
        this.secretarias['sur'].push(secretaria);
      }
    },
    cambiarSecretaria(secretaria) {
      if (!secretaria) {
        this.$store.commit('error-notification', 'Se debe ingregar una secretaria');
      }
      const {sadNombre, sadCodigo, regCodigo, prvCodigo} = secretaria;
      const usuario = {
        ...this.usuario,
        sadNombre,
        sadCodigo,
        regCodigo,
        prvCodigo
      };
      this.$store.commit('setUsuario', usuario);
      this.cerrar();
    },
    cerrar() {
      this.$router
        .push("/locales")
        .catch(() => {} );
      this.$emit('cerrar');
    },
  }
};

</script>

<style>
.v-list-item--dense, .v-list--dense .v-list-item {
   min-height: 30px;
}
.underline {
  text-decoration: underline;
}
</style>
