import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import archivos from '@demre/client-common/src/plugins/archivos';
import store from './store'
import router from './router'
import graphql from './utils/graphql';
import validator from './utils/validator';

Vue.use(archivos);

Vue.config.productionTip = false

Vue.use(validator);

async function init() {
  const query = {
    query: `
      query {
        usuario {
          sadCodigo
          sadNombre
          regCodigo
          prvCodigo
          rol {
            idRol
            nombre
          }
          username
        }
        fases: fasePrueba {
          fspCodigo
          fspDescripcion
          vigente
          orden
          fspTipo
        }
        modulos {
          nombre
          activo
          fechaInicio
          fechaFin
          fase {
            fspCodigo
            fechaInicio
            fechaFin
          }
        }
      }
    `,
  };
  const { usuario, fases, modulos } = await graphql.request(query);

  const roles = {
    'admin': 'JEFE DE ADMISIÓN',
    'jefe-admision': 'JEFE DE ADMISIÓN',
    'secretario-admision': 'SECRETARIO DE ADMISIÓN',
    'colaborador': 'COLABORADOR',
    'personal-demre' : 'PERSONAL DEMRE'
  };

  if (usuario && fases.length) {
    store.commit('setUsuario', usuario);
    store.commit('setFases', fases);
    store.commit('setModulos', modulos);
    store.commit('setRoles', roles);
  }
}
store.dispatch('session/load')
  .then(init)
  .then(() => {
    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })

window.store = store;
