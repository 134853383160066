import Vue from 'vue';
import Vuex from 'vuex';
import session from '@demre/client-common/src/components/jwt-refresher/session';
import graphql from '../utils/graphql';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    usuario: {},
    fases: [],
    sedes: [],
    nominaExaminador: [],
    funciones: [],
    bancos: [],
    estadoConfirmar: null,
    modulos: [],
    roles:{}
  },
  mutations: {
    setUsuario(state, usuario) {
      state.usuario = usuario;
    },
    setFases(state, sad) {
      state.fases = sad;
    },
    setSedes(state, sedes) {
      state.sedes.push(...sedes);
    },
    setNominaExaminador(state, sad) {
      state.nominaExaminador = sad;
    },
    setFunciones(state, sad) {
      state.funciones = sad;
    },
    setBancos(state, bancos) {
      state.bancos = bancos;
    },
    setEstadoConfirmar(state, update) {
      state.estadoConfirmar = update;
    },
    setModulos(state, sad) {
      state.modulos = sad;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
  },
  actions: {
    async getSedes({ commit, state }, { fspCodigo, sadCodigo }) {
      let sedes = state.sedes.filter(x => x.fspCodigo == fspCodigo);
      if (!sedes.length) {
        const query = {
          query: `query ($fspCodigo: Int!, $sadCodigo: Int!) {
            sedes: sedesByFaseSecretaria(fspCodigo: $fspCodigo, sadCodigo: $sadCodigo) {
              fspCodigo
              sedCodigo
              sede {
                sadCodigo
                sedNombre
                regCodigo
                prvCodigo
                comCodigo
              }
              sedeConfirmada {
                estado
              }
            }
          }
          `,
          variables: {
            fspCodigo,
            sadCodigo,
          }
        };
        ({ sedes } = await graphql.request(query));
        if (sedes.length) {
          commit('setSedes', sedes);
        }
      }
      return sedes.filter(({ sede }) => sede.sadCodigo == sadCodigo);
    },
    async getFunciones({ commit, state }, { ids }) {
      let funciones = state.funciones.filter(({fneCodigo}) => [ids].includes(fneCodigo));
      if (!funciones.length) {
        const query = {
          query: `query ($ids: [Int!]!) {
            funciones: funcionesExaminador(ids: $ids) {
              fneCodigo
              nombre
            }
          }
          `,
          variables: {
            ids,
          }
        };
        ({ funciones } = await graphql.request(query));
        if (funciones.length) {
          commit('setFunciones', funciones);
        }
      }
      return funciones;
    },
  },
  modules: {
    session,
  }
});
