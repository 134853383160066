const requiredField = (field) => (v) => !!v || `${field} es requerido`;

const phoneNumber = (v) => {
  const error = 'Formato incorrecto, debe ser numérico y de 9 dígitos'
  if (v === 0) {
    return error;
  } else if (!v) {
    return true;
  } else {
    return /^[0-9]{9}$/.test(v) || error;
  }
};

const isEmail = (v) => {
  if (v) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email no es válido';
  }
  return true;
};

const onlyNumbers = (field) => (v) => {
  if (v) {
    return /^[0-9]+$/.test(v) && v > 0 || `${field} solo debe contener números`;
  }
  return true;
};

const isNumber = (v) =>  /^[0-9]+$/.test(v) || `Valor debe ser numérico`;

const maximaCapacidadSala = (value) => v => v >= value || 'Máxima capacidad de sala debe ser mayor o igual a Capacidad PeSD';

const capacidadPesd = (value) => v => v <= value || 'Capacidad PeSD debe ser menor o igual a Máxima capacidad de sala';

export default function (Vue) {
  Vue.mixin({
    methods: {
      $chkRequiredField: requiredField,
      $chkPhoneNumber: phoneNumber,
      $chkIsEmail: isEmail,
      $chkOnlyNumbers: onlyNumbers,
      $chkIsNumber: isNumber,
      $chkMaximaCapacidad: maximaCapacidadSala,
      $chkCapacidadPesd: capacidadPesd,
    }
  });
}
