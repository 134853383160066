<template>
  <v-app>
    <v-system-bar
      app
      window
      color="orange"
    >
      <strong style="white-space: nowrap;">
        <v-btn text color="secondary">
          {{ sad }}
        </v-btn>
      </strong>
    </v-system-bar>
    <d-header
      v-model="drawer"
      :title="title"
      :username="rol"
      :logged="true"
      :profile="profile"
      :show-side-icon="true"
    />
    <v-navigation-drawer v-model="drawer" fixed clipped app width="320">
      <v-list dense>
        <v-list-item v-if="rol == 'JEFE DE ADMISIÓN'" @click="loadSecretarias">
          <v-list-item-action class="mr-2">
            <v-icon>$alt_route</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Selección de Secretaría de Admisión
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-for="(item, i) in items">
          <v-list-group v-if="item.children" :key="i" v-model="item.active" no-action>
            <v-list-item slot="activator" class="px-0">
              <v-list-item-action class="mr-2">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-for="(child, j) in item.children" :key="i + '-' + j" :to="child.href" class="pl-6">
              <v-list-item-action v-if="child.icon" class="mr-2">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-badge v-if="child.count > 0" color="red">
                <span slot="badge">{{ child.count }}</span>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-badge>
              <v-list-item-content v-else>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item v-else :key="i" :to="item.href">
            <v-list-item-action class="mr-2">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template #append>
        <div class="text-center mb-5" style="font-size: 12px;">
          v{{ appVersion }}
        </div>
      </template>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
      <d-refresher />
      <d-notifier />
      <d-seleccion-secretaria
        :value="seleccionSadDialog"
        @cerrar="cerrar"
      />
    </v-main>
    <d-footer />
  </v-app>
</template>

<script>
import Header from '@demre/client-common/src/components/header';
import Refresher from '@demre/client-common/src/components/jwt-refresher';
import Footer from '@demre/client-common/src/components/footer';
import Notifier from '@demre/client-common/src/components/notifier';
import SeleccionSecretaria from './pages/aplicacion/SeleccionSecretaria';

export default {
  components: {
    'd-header': Header,
    'd-refresher': Refresher,
    'd-footer': Footer,
    'd-notifier': Notifier,
    'd-seleccion-secretaria': SeleccionSecretaria,
  },
  data: function() {
    return {
      title: 'Secretarías de Admisión',
      drawer: true,
      seleccionSadDialog: false,
    };
  },
  computed: {
    appVersion() {
      return process.env.VUE_APP_VERSION;
    },
    roles() {
      return this.$store.state.roles;
    },
    rol() {
      //se asume que el usuario solo tendrá un rol, esto puede cambiar a futuro
      return this.roles[this.$store.state.usuario.rol[0].nombre];
    },
    sad() {
      return this.$store.state.usuario.sadNombre;
    },
    tieneRolEstadistica() {
      return ['JEFE DE ADMISIÓN', 'PERSONAL DEMRE'].some(r => r == this.rol);
    },
    items() {
      const subMenuEstadisticas = [
        { icon: '$clipboard_list_outline', text: 'Inscritos por Sede / Región', href: { name: 'inscritosSede' }, active: false },
        { icon: '$clipboard_list_outline', text: 'Seguimiento Preliminar', href: { name: 'inscritosPesd' }, active: false },
        { icon: '$clipboard_list_outline', text: 'Seguimiento Oficial', href: { name: 'inscritosOficial' }, active: false },
      ];
      return [
        {icon: 'search', text: 'Locales de aplicación', href: { name: 'locales'}, active: false},
        {icon: 'account_balance', text: 'Establecimientos Educacionales', href: { name: 'colegios'}, active: false},
        {icon: 'list', text: 'Registro', href: { name: 'registro'}, active: false},
        {icon: 'local_library', text: 'Inscritos', href: { name: 'inscritos'}, active: false},
        {icon: 'list', text: 'Estadísticas' , active: false,
          children: [
            { icon: '$clipboard_list_outline', text: 'Avance Inscripción por Establecimiento', href: { name: 'avanceInscripcion' }, active: false },
            ...(this.tieneRolEstadistica ? subMenuEstadisticas : [])
          ]
        },
      ]
    },
    profile() {
      return [
        { title: 'Salir', icon: 'power_settings_new', href: 'logout' }
      ];
    }
  },
  methods: {
    async loadSecretarias() {
      this.seleccionSadDialog = true;
    },
    async cerrar() {
      this.seleccionSadDialog = false;
    },
  }
};
</script>

<style>
  .my-upper input {
    text-transform: uppercase
  }
</style>

